body {
  background: #6c757d !important;
  padding: 1rem 10%;
}

* {
  color: #fff;
}

img {
  height: 60px;
  width: 90px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link-flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.link,
a {
  color: #fff !important;
  text-decoration: none !important;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -55%);
}
